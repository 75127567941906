<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="danger">
      <b-navbar-brand
        >SOCO - Feira {{ new Date().getFullYear() }} (Admin)</b-navbar-brand
      >
      <b-navbar-nav class="mr-auto">
        <b-nav-form> </b-nav-form>
      </b-navbar-nav>
      <b-navbar-brand right>{{ form.nome + " - " + anel }}</b-navbar-brand>
    </b-navbar>

    <b-row style="background-color: #ccc; padding: 2px 30px; text-align: right">
      <b-col>
        <div>{{ total }} aves inscritas</div>
      </b-col>
    </b-row>
    <b-container style="margin-top: 10px">
      <b-card>
        <b-overlay :show="show" rounded="sm">
          <b-table responsive striped hover :items="items">
            <template #head(id)=""> Linha </template>
            <template #cell(id)="row">
              {{ items.indexOf(row.item) + 1 }}
            </template>
          </b-table>
        </b-overlay>
      </b-card>
      <b-card style="margin-top: 10px">
        <b-overlay :show="show" rounded="sm">
          <b-table responsive striped hover :items="itemsGrupos">
            <template #head(id)=""> Linha </template>
            <template #cell(id)="row">
              {{ items.indexOf(row.item) + 1 }}
            </template>
          </b-table>
        </b-overlay>
      </b-card>
      <b-card style="margin-top: 10px">
        <b-overlay :show="show" rounded="sm">
          <b-table
            id="element-to-convert"
            responsive
            striped
            hover
            :items="itemsTodos"
            :fields="fields"
          >
            <template #cell(preco)="row">
              {{ row.item.preco + ",00" }}
            </template>
          </b-table>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      fields: [
        "nome",
        "especie",
        "cor",
        "sexo",
        "anel",
        "numero",
        "ano",
        "preco",
        "gaiola",
      ],
      itemsTodos: [],
      items: [],
      itemsGrupos: [],
      show: false,
      form: {
        anel: "",
        nome: "",
        sexo: "Indefinido",
        ano: null,
        cpf: null,
        especie: null,
        numero: null,
        preco: null,
      },
      sexo: ["Indefinido", "Macho", "Femea", "Casal"],
      cpf: "",
      anel: "",
      disabled: true,
      valido: true,
      url: "https://apifeira.socochapeco.com.br/",
      total: 0,
      //url: "https://127.0.0.1:8000/",
    };
  },
  mounted() {
    this.totais();
  },
  methods: {
    totais() {
      this.show = true;
      this.axios.get(this.url + "inscricoes/totais").then((response) => {
        this.items = response.data.itens;
        this.total = response.data.total;
        this.itemsGrupos = response.data.totalGrupos;
        this.show = false;
      });
      this.axios.get(this.url + "inscricoes/").then((response) => {
        this.itemsTodos = response.data;
        this.show = false;
      });
    },
    makeToast() {
      this.$bvToast.toast("Preencha todos os campos!", {
        variant: "danger",
        title: "Campos Obrigatórios!",
        solid: true,
        autoHideDelay: 3000,
      });
    },
  },
};
</script>
